import { Label } from "@medusajs/ui"
import clsx from "clsx"
import { InputHTMLAttributes, forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from "react"
import { EyeIcon, EyeOffIcon } from "src/modules/common/icons"

export type InputProps = Omit<Omit<InputHTMLAttributes<HTMLInputElement>, "size">, "placeholder"> & {
  label: string
  errors?: Record<string, unknown>
  touched?: Record<string, unknown>
  name: string
  topLabel?: string
  large?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type, name, label, touched, required, topLabel, large, ...props }, ref) => {
    const id = useId()
    const inputRef = useRef<HTMLInputElement>(null)
    const [showPassword, setShowPassword] = useState(false)
    const [inputType, setInputType] = useState(type)

    useEffect(() => {
      if (type === "password" && showPassword) {
        setInputType("text")
      }

      if (type === "password" && !showPassword) {
        setInputType("password")
      }
    }, [type, showPassword])

    useImperativeHandle(ref, () => inputRef.current!)

    return (
      <div className="flex flex-col w-full">
        {topLabel && <Label className="mb-2 text-sm font-medium">{topLabel}</Label>}

        <div className="relative z-0 flex w-full text-sm">
          <input
            ref={inputRef}
            id={id}
            type={inputType}
            name={name}
            placeholder=" "
            required={required}
            className={clsx(
              "bg-ui-bg-field focus:outline-none focus:ring-0 focus:shadow-borders-interactive-with-active hover:bg-ui-bg-field-hover block w-full pt-4 pb-1 mt-0 border rounded-full appearance-none",
              {
                "px-8 h-14 text-lg": large,
                "px-4 h-11 text-base": !large,
              },
            )}
            {...props}
          />

          {/* biome-ignore lint/a11y/useKeyWithClickEvents: */}
          <label
            htmlFor={id}
            onClick={() => inputRef.current?.focus()}
            className={clsx(
              "origin-0 text-subtle absolute flex items-center justify-center transition-all duration-300",
              {
                "mx-8 top-4 text-base": large,
                "mx-4 top-3 text-sm": !large,
              },
            )}
          >
            {label}
            {required && <span className="text-error">*</span>}
          </label>

          {type === "password" && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-subtle focus:outline-none focus:text-default top-3 absolute right-0 px-4 transition-all duration-150 outline-none"
            >
              {showPassword ? <EyeIcon /> : <EyeOffIcon />}
            </button>
          )}
        </div>
      </div>
    )
  },
)

Input.displayName = "Input"
