import getTimezoneOffset from "get-timezone-offset"
import { AppLocale } from "src/localization/localization"
import { formatDateMedium } from "./date"

export const germanTimeZoneOffset = getTimezoneOffset("Europe/Berlin")

const germanTimeOffsetHours = (-germanTimeZoneOffset / 60).toString().padStart(2, "0")
export const germanTimeOffsetIso = `+${germanTimeOffsetHours}:00` as const

export function formatAsDateInGermany(date: Date | string, appLocale: AppLocale) {
  if (!date) {
    return ""
  }

  const dateUtc = new Date(new Date(date).toISOString())
  const dateGermany = new Date(dateUtc.getTime() - germanTimeZoneOffset * 60_000)

  const formatted = formatDateMedium(dateGermany, appLocale)

  return formatted
}

export function getEndOfDayInGermany() {
  const nowLocal = new Date()
  const nowUtc = new Date(nowLocal.getTime() + nowLocal.getTimezoneOffset() * 60_000)
  const nowGermany = new Date(nowUtc.getTime() - germanTimeZoneOffset * 60_000)

  const endOfDayGermany = new Date(nowGermany)
  endOfDayGermany.setHours(23, 59, 59, 999)

  const endOfDayUtc = new Date(endOfDayGermany.getTime() + germanTimeZoneOffset * 60_000)
  const endOfDayLocal = new Date(endOfDayUtc.getTime() - endOfDayUtc.getTimezoneOffset() * 60_000)

  return endOfDayLocal
}
