import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"
import { isCoarsePointer } from "./device"

export function scrollToTop() {
  window.scrollTo({ top: 0 })
}

export type ScrollOptions = Parameters<typeof smoothScrollIntoView>[1]

export async function scrollIntoView(element: Element | null, options?: ScrollOptions) {
  if (!element) {
    return
  }

  const smoothScrollIntoViewIfNeeded = await import("smooth-scroll-into-view-if-needed").then(
    (module) => module.default,
  )

  const mobile = isCoarsePointer()

  smoothScrollIntoViewIfNeeded(element, {
    behavior: mobile ? "instant" : "smooth",
    block: mobile ? "start" : "nearest",
    scrollMode: mobile ? "always" : "if-needed",
    ...options,
  })
}
