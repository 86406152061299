"use client"

import { ComponentProps } from "react"
import { scrollIntoView } from "src/utilities/scroll"

export type ScrollLinkProps = ComponentProps<"button"> & {
  targetId: string
}

export const ScrollLink = ({ targetId, ...props }: ScrollLinkProps) => {
  const scroll = () => {
    const element = document.getElementById(targetId)

    if (element) {
      scrollIntoView(element, {
        behavior: "smooth",
        block: "start",
      })
    }
  }

  return (
    <button type="button" {...props} onClick={scroll}>
      {props.children}
    </button>
  )
}
