"use client"

import clsx from "clsx"
import { Fragment, useEffect, useState } from "react"
import { useIsClient } from "src/hooks/useIsClient"
import { getEndOfDayInGermany } from "src/utilities/germanTime"

export type CountdownProps = {
  until: string | undefined
}

export const Countdown = ({ until }: CountdownProps) => {
  // start at 24 hours
  const [remaining, setRemaining] = useState(0)
  const isClient = useIsClient()

  useEffect(() => {
    function tick() {
      const currentDate = new Date()
      const referenceDate = until ? new Date(until) : getEndOfDayInGermany()
      const totalMilliseconds = referenceDate.getTime() - currentDate.getTime()

      setRemaining(totalMilliseconds)
    }

    tick()

    const interval = setInterval(tick, 1000)

    return () => clearInterval(interval)
  }, [until])

  const days = Math.floor(remaining / (1000 * 60 * 60 * 24))
  const hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((remaining % (1000 * 60)) / 1000)

  const parts = [
    days > 0 ? { value: days.toString().padStart(2, "0"), label: "Tage" } : undefined,
    days > 0 || hours > 0 ? { value: hours.toString().padStart(2, "0"), label: "Stunden" } : undefined,
    days > 0 || hours > 0 || minutes > 0 ? { value: minutes.toString().padStart(2, "0"), label: "Minuten" } : undefined,
    { value: seconds.toString().padStart(2, "0"), label: "Sekunden" },
  ].filter(Boolean)

  if (isClient && remaining <= 0) {
    return null
  }

  return (
    <section
      className={clsx("whitespace-nowrap text-brand-primary sm:py-8 w-full max-w-full overflow-hidden text-center", {
        invisible: !isClient,
      })}
    >
      <div className="sm:scale-125 flex items-center justify-center">
        {parts.map((part, index) => (
          <Fragment key={index}>
            {part && (
              <div className="group flex flex-col w-[4.5rem]">
                <div className="border-l-brand-secondary-lighter group-first:border-l-transparent flex items-center justify-center w-full h-16 font-mono text-5xl border-l-2">
                  {part.value}
                </div>
                <div className="text-muted flex items-center justify-center w-full h-8 text-sm">{part.label}</div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </section>
  )
}
