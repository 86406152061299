"use client"

import clsx from "clsx"
import { FormEvent, useState } from "react"
import { contactStore, mergeContactData } from "src/integrations/lib/state"
import { FallbackErrorMessage } from "src/modules/(checkout)/checkout/components/FallbackErrorMessage"
import { signUpForNewsletter } from "src/modules/comingSoon/actions/klaviyo"
import { Input } from "src/modules/common/components/Input"
import { Spinner } from "src/modules/common/icons"
import { reportError } from "src/utilities/error"

export const SignupForm = () => {
  const email = contactStore((state) => state.email)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  function handleEmailChange(email: string) {
    mergeContactData({ email })
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    try {
      setLoading(true)
      setSuccess(false)
      setError(false)

      const result = await signUpForNewsletter(email || "")

      if (!result.ok) {
        throw new Error(result.status + " " + result.statusText + ": " + result.body)
      }

      setSuccess(true)
    } catch (error) {
      reportError("Failed to sign up for newsletter", error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  if (success) {
    return (
      <div className="rounded-3xl bg-brand-secondary-lighter flex flex-col gap-2 px-4 py-8">
        <p className="font-bold">Fast geschafft!</p>

        <p>
          Bestätige deine Anmeldung in der Email, die wir dir gesendet haben. Schau dafür in deinem Email-Postfach nach.
        </p>

        <p>Du wirst bald von uns hören!</p>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} inert={loading ? ("true" as any) : undefined}>
      <div className="md:flex-row whitespace-nowrap flex flex-col items-center justify-center gap-4">
        <Input
          label="Deine E-Mail"
          name="email"
          autoComplete="email"
          value={email || ""}
          onChange={(event) => handleEmailChange(event.target.value)}
          required
          large
        />

        <button
          type="submit"
          disabled={loading}
          className={clsx(
            "active:shadow-none hover:transition-none md:w-auto flex items-center justify-center w-full !h-14 text-lg font-medium text-white uppercase transition-[colors,transform] rounded-full shadow active:scale-95",
            {
              "bg-brand-primary hover:bg-gray-800 active:bg-gray-600 px-8": !loading,
              "bg-gray-800 opacity-50 cursor-not-allowed px-12": loading,
            },
          )}
        >
          {loading ? <Spinner className="w-6 h-6" /> : "Sagt Bescheid!"}
        </button>
      </div>

      {error && <FallbackErrorMessage />}
    </form>
  )
}
