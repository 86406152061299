import { format } from "date-fns"
import { AppLocale } from "src/localization/localization"
import { germanTimeOffsetIso } from "src/utilities/germanTime"

export function getDateFnsLocale(appLocale: AppLocale) {
  switch (appLocale) {
    case "en":
      return require("date-fns/locale/en-GB").enGB
    default:
      return require("date-fns/locale/de").de
  }
}

export function formatDate(date: Date | string, appLocale: AppLocale) {
  if (!date) {
    return ""
  }

  const dateObj = new Date(date)

  if (!dateObj.getTime()) {
    return ""
  }

  const dateFnsLocale = getDateFnsLocale(appLocale)

  return format(dateObj, "P", { locale: dateFnsLocale })
}

export function formatDateMedium(date: Date | string, appLocale: AppLocale) {
  const dateObj = new Date(date)
  dateObj.setHours(12)

  const dateFnsLocale = getDateFnsLocale(appLocale)

  return format(dateObj, "P", { locale: dateFnsLocale })
}

export function formatProductShippingDateToIso(date: string | undefined) {
  if (!date) {
    return ""
  }

  const [day, month, year] = date.split(".").map((part) => +part)

  if (
    typeof year !== "number" ||
    typeof month !== "number" ||
    typeof day !== "number" ||
    Number.isNaN(year) ||
    Number.isNaN(month) ||
    Number.isNaN(day)
  ) {
    throw new Error(`Invalid date: ${date}`)
  }

  const yearString = year.toString()
  const monthString = month.toString().padStart(2, "0")
  const dayString = day.toString().padStart(2, "0")
  const dateGermanyIso = `${yearString}-${monthString}-${dayString}T00:00:00.000${germanTimeOffsetIso}`

  return dateGermanyIso
}
