"use client"

import { useLocalizedText } from "src/localization/client"
import { ErrorMessage } from "src/modules/(checkout)/checkout/components/ErrorMessage"

export type FallbackErrorMessageProps = {
  className?: string
}

export const FallbackErrorMessage = ({ className }: FallbackErrorMessageProps) => {
  const T = useLocalizedText(text)

  return <ErrorMessage message={T.message} className={className} />
}

const de = {
  message: "Das hat leider nicht geklappt. Bitte versuche es erneut.",
}

const en: typeof de = {
  message: "Something went wrong. Please try again.",
}

const text = { de, en }
