import clsx from "clsx"
import { FC } from "react"
import { IconBaseProps } from "react-icons"
import {
  LuArrowRight,
  LuCheckCircle,
  LuChevronDown,
  LuChevronLeft,
  LuChevronRight,
  LuChevronsUpDown,
  LuCreditCard,
  LuDot,
  LuEye,
  LuEyeOff,
  LuHome,
  LuImage,
  LuInfo,
  LuLoader2,
  LuLogOut,
  LuMapPin,
  LuMenu,
  LuPackage,
  LuPenSquare,
  LuPlus,
  LuSearch,
  LuShoppingBag,
  LuTrash2,
  LuUser2,
  LuX,
} from "react-icons/lu"

export type IconProps = IconBaseProps
export type Icon = FC<IconProps>

const wrap = (Icon: Icon) => {
  const Wrapped: Icon = ({ size = 20, ...props }) => {
    return <Icon {...props} size={size} />
  }
  return Wrapped
}

export const ChevronDownIcon = wrap(LuChevronDown)
export const ChevronLeftIcon = wrap(LuChevronLeft)
export const ChevronRightIcon = wrap(LuChevronRight)
export const ChevronUpDownIcon = wrap(LuChevronsUpDown)
export const CreditCardIcon = wrap(LuCreditCard)
export const ArrowRightIcon = wrap(LuArrowRight)
export const DotIcon = wrap(LuDot)
export const EditIcon = wrap(LuPenSquare)
export const EyeIcon = wrap(LuEye)
export const EyeOffIcon = wrap(LuEyeOff)
export const HomeIcon = wrap(LuHome)
export const ImageIcon = wrap(LuImage)
export const InfoIcon = wrap(LuInfo)
export const MapPinIcon = wrap(LuMapPin)
export const MenuIcon = wrap(LuMenu)
export const PackageIcon = wrap(LuPackage)
export const PlusIcon = wrap(LuPlus)
export const SearchIcon = wrap(LuSearch)
export const ShoppingBagIcon = wrap(LuShoppingBag)
export const SignOutIcon = wrap(LuLogOut)
export const StepCompleteIcon = wrap(LuCheckCircle)
export const TrashIcon = wrap(LuTrash2)
export const UserIcon = wrap(LuUser2)
export const XIcon = wrap(LuX)

export const Spinner = wrap((props) => {
  return <LuLoader2 {...props} className={clsx("animate-spin", props.className)} />
})
