export type FallbackErrorMessageProps = {
  message: string | null | undefined
  className?: string
}

export const ErrorMessage = ({ message, className }: FallbackErrorMessageProps) => {
  if (!message) {
    return null
  }

  const cleanedMessage = message.replace(/^Error: /, "")

  return <div className={["text-error text-xs font-normal my-2", className].join(" ")}>{cleanedMessage}</div>
}
